.searchBox{
  background-color: white;
  height: 100%;
}

.searchBox .MuiFilledInput-root .MuiAutocomplete-tag{
  background-color: yellow;
}

.searchBox .MuiTextField-root,
.searchBox .MuiFilledInput-root{
  height: 100%;
}

.searchImageContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f0f0f0;
  border-bottom: 1px solid #8c8c8c;
  border-right: 1px solid #8c8c8c;
}
