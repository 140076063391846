.rounded-image{
    border-radius: 50%;
}

.wobble {
    animation: wiggle 10s linear infinite;
}

@keyframes wiggle {
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
}

.wobble {
    transform-origin: 50% 5em;
}

.wobble{
    padding-top: 5px;
    background-color: red;
    position: fixed;
    opacity: 1;
    z-index: 99999;
    padding-bottom: 5px;
    top: 80%;
    right: 5%;
    width: 200px !important;
    border: 2px dashed white;
    outline: 2px solid black;
}

body, input, textarea, button, .button, .faux-button, .faux-button.more-link, .wp-block-button__link, .wp-block-file__button {
    font-family: 
    'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"' !important
}



.header-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center
}

.header-links-row {
    width: 100%
}

.header-col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px
}

.cookie-notice {
    display: none
}

.show-cookie-notice {
    display: block;
    padding: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #6f232a;
    color: #fff;
    width: 100%;
    z-index: 100;
}

.show-cookie-notice .col {
    display: flex;
    justify-content: space-evenly;
    align-items: center
}

.footer {
    background-color: #000;
    color: #fff;
    padding: 60px 15px;
    margin-top: 40px;
}

.footer a {
    color: #fff
}

.footer-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.ads-col {
    text-align: center
}

.product-image-col {
    border-bottom: 1px solid #388e3c
}

.product-image-col .product-price {
    font-weight: 900
}

.product-image-col .add-btn-container {
    padding-top: 15px;
    padding-bottom: 15px
}

.shop-total {
    font-weight: 900
}

.cart-col{
    padding-top: 15px;
}

.cart-col .shopping-list-btn-container {
    flex-direction: column;
    width: 100%
}

.cart-col .shopping-list-btn-container .shopping-list-btn {
    width: 100%
}

.cart-col .supermarket-items {
    height: 70px;
    flex-direction: row !important;
    align-items: center !important;
    padding: 5px !important
}

.cart-col .supermarket-items .shop-logo {
    max-height: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important
}

.cart-col .supermarket-items .shop-total {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important
}

.shopping-list {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.shopping-list .shopping-list-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.shopping-list .shopping-list-btn-container .shopping-list-btn {
    position: relative;
    margin-right: 15px
}

.shopping-list .shopping-list-btn-container .shopping-list-btn .shopping-list-count {
    position: absolute;
    top: -8px;
    right: -10px;
    background: red;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px
}

.add-btn-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.add-btn-container .add-to-list {
    align-self: center;
    position: relative
}

.add-btn-container .add-to-list .qty-count {
    position: absolute;
    top: -8px;
    right: -10px;
    background: red;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px
}

.product-panel .add-remove-ctn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.remove-from-list {
    cursor: pointer;
    margin-left: 5px
}

.product-image-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.alt-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.alt-products .alt-product {
    width: 100%;
    padding: 15px 5px;
    border-bottom: 1px solid #388e3c
}

.alt-products .shop-logo, .alt-products .alt-product-image {
    text-align: center
}

.alt-products .alt-product-image img {
    max-height: 100%;
    max-width: 100%;
    padding: 5px
}

.alt-products .alt-product-title {
    font-weight: 700
}

.alt-products .alt-product-price {
    font-weight: 900;
    text-align: center
}

a.alt-product.row:hover,
.product:hover {
    cursor: pointer;
    opacity: 0.7
}

.product-detail-image {
    display: flex;
    justify-content: center;
    align-items: center
}

.product-detail-image img {
    max-width: 100%;
    max-height: 480px;
    width: auto;
    height: auto
}

.product-panel {
    background: #fff;
    padding: 15px
}

.store-image-container {
    height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
}

.reset-row {
    min-height: 38px
}

.search-bar.sticky {
    padding-top: 40px
}

.shopping-list-btn:hover, .select-category-btn:hover {
    opacity: 0.7;
    background-color: black
}

.shopping-list-btn, .select-category-btn {
    background-color: black;
    border: none
}

.my-modal-dialog .main-dialog-col {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px
}

.on-offer-row {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px
}

.store-image-container > img {
    width: 100%;
    max-height: 100%;
    max-width: 100%
}

.checkbox-row {
    padding-bottom: 15px
}

.checkbox-row label {
    font-size: 16px;
    font-weight: 700
}

.checkbox-panel {
    background: #fff;
    padding: 15px
}

.checkbox-container:hover, .checkbox-container label:hover {
    cursor: pointer;
    background-color: black;
    color: white
}

.checkbox-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center
}

.checkbox-container label {
    margin-bottom: 0;
    width: 100%
}

.checkbox-container input[type='checkbox'] {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555
}

.checkbox-container input[type='checkbox']:checked {
    background: #388e3c
}

.main-row {
    padding-top: 15px
}

.store-select {
    width: 100%;
    padding: 5px
}

.cat-heading {
    padding-top: 30px;
    margin-bottom: 0
}

.change-store, .cat-heading {
    padding-left: 15px;
    padding-right: 15px;
    color: #388e3c;
    font-weight: 700
}

.reset-btn {
    width: 100%
}

.product-title {
    overflow: hidden;
    font-size: 14px;
}

.search-bar {
    background-color: #388e3c
}

.header-links-row button {
    background-color: white;
    color: #388e3c;
    border: none;
    font-weight: 700
}

.header-links-row button:hover {
    background-color: white;
    color: #388e3c;
    opacity: 0.7
}

#header-row {
    background-color: #388e3c;
    padding-top: 20px;
    padding-bottom: 20px
}

#header-row p {
    color: white
}

.site-name a:hover {
    text-decoration: none
}

.site-name h1, .site-name h2, .site-name h5 {
    color: white
}

.site-name h1, .site-name h2 {
    font-weight: 900;
}

.app-container {
    padding: 0;
}

#header-row, .filterable-product-list, .top-10-header-row {
    padding-left: 15px;
    padding-right: 15px
}

.site-name a {
    color: #212529
}

.search-btn{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.sort-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end
}

.sort-col label {
    color: white;
    margin-bottom: 0;
    font-weight: 900
}

.fixed-product-list-col > .shop-logo {
    height: 75px;
    background-color: blanchedalmond;
    display: flex;
    justify-content: center;
    align-items: center
}

.search-cheapest-col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px
}

.fixed-product-list-col .fixed-product {
    height: 300px;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #0002;
    text-decoration: none
}

.fixed-product-list-col .fixed-product .product-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    background-color: #fff;
    flex: 6;
    align-self: center
}

.fixed-product-list-col .fixed-product .product-image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto
}

.fixed-product-list-col .fixed-product .shop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    background-color: #fff;
    flex: 1;
    align-self: center
}

.fixed-product-list-col .fixed-product .product-details {
    flex: 3;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px
}

.fixed-product-list-col .fixed-product .product-details .product-price {
    font-weight: 900
}

.fixed-product-list-col .fixed-product .product-details .view-product-details {
    align-self: flex-end
}

.error-msg {
    color: #fff;
    background-color: red;
    font-weight: 900;
    font-size: 16px;
    padding: 5px;
    margin-left: -15px;
    margin-right: -15px
}

.error-ui {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    flex-direction: column;
    background-color: #ff0000ad
}

.hide-scroll {
    overflow-y: hidden
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: #388e3c;
}

.add-remove-ctn {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.my-shopping-list {
    background-color: white;
    padding: 5px;
    margin-bottom: 15px
}

.total-price {
    font-weight: 900
}

.total-price-label {
    text-align: right
}

.total-price-row {
    padding: 15px
}

.list-item {
    border-bottom: 1px solid #000
}

.shop-total-row {
    background-color: #00000026
}

.list-action-btns {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center
}

.print-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.qty {
    text-align: right
}

.no-results-col {
    display: flex;
    justify-content: center;
    align-items: center
}

.no-results-found {
    padding-top: 30px;
    padding-bottom: 30px
}

.disabled {
    opacity: 0.2
}

.product-image {
    align-self: center
}

.filterable-product-list {
    overflow-x: hidden
}

.filterable-product-list .supermarket-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    padding: 15px 15px 5rem 15px
}

.filterable-product-list .supermarket-items .image, .filterable-product-list .supermarket-items .shop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    background-color: #fff
}

.filterable-product-list .supermarket-items .image img
{
    max-width: 100%;
    height: auto;
    max-height: 75px;
    width: auto
}

.filterable-product-list .supermarket-items .shop-logo {
    justify-content: flex-start
}

.filterable-product-list .supermarket-items .all-shop-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding-top: 30px
}

.filterable-product-list .supermarket-items .all-shop-items .list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px
}

.filterable-product-list .supermarket-items .shop-total-row {
    align-self: stretch
}

.filterable-product-list .supermarket-items .shop-total-row .shop-total-label {
    text-align: right
}

.filterable-product-list .my-modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px;
    background-color: #00000055;
    width: 100%;
    height: 100%;
    z-index: 200;
    overflow-y: auto
}

.filterable-product-list .my-modal-dialog .close-dialog {
    cursor: pointer;
    display: flex;
    justify-content: flex-start
}

.filterable-product-list .my-modal-dialog .close-dialog svg, .filterable-product-list .my-modal-dialog .close-dialog img {
    background-color: white;
    border-radius: 24px
}

.filterable-product-list .search-bar.sticky {
    border-bottom: 3px solid #0000004a
}

.filterable-product-list .search-bar {
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px
}

.filterable-product-list .search-bar .filter-by-category {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start
}

.filterable-product-list .search-bar .filter-by-category .active-category-filters-label {
    margin-bottom: 0
}

.filterable-product-list .search-bar .filter-by-category .select-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.filterable-product-list .search-bar .filter-by-category .select-btn-container .select-category-btn {
    position: relative
}

.filterable-product-list .search-bar .filter-by-category .select-btn-container .select-category-btn .filter-count {
    position: absolute;
    top: -8px;
    right: -10px;
    background: red;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px
}

.filterable-product-list .search-bar .filter-by-category .select-btn-container .clear-category-filter {
    cursor: pointer;
    margin-left: 5px
}

.filterable-product-list .search-bar .filter-by-category .active-category-filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap
}

.filterable-product-list .search-bar .filter-by-category .active-category-filters .active-filter {
    font-weight: 200;
    background-color: green;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-right: 2px
}

.filterable-product-list .search-bar .search-container {
    background-color: white;
    border: 1px solid #000000b3;
    border-radius: 24px;
    height: 44px
}

.filterable-product-list .search-bar .search-container .search-icon {
    display: flex;
    justify-content: center;
    align-items: center
}

.filterable-product-list .search-bar .search-container .search-icon svg, .filterable-product-list .search-bar .search-container .search-icon img {
    color: #9aa0a6;
    fill: #000000;
    line-height: 24px
}

.filterable-product-list .search-bar .search-container input {
    width: 100%;
    height: 44px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    word-wrap: break-word;
    outline: none;
    tap-highlight-color: transparent;
    font-size: 16px
}

.filterable-product-list .search-bar .search-container .clear-search {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.filterable-product-list .product-list {
    justify-content: flex-start;
    align-items: center
}

.filterable-product-list .product-list .product {
    overflow: hidden;
    height: 400px;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #0002;
    text-decoration: none
}

.latest-deal {
    background-color: red;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

.latest-deal .percentage-off {
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 5px;
}

.filterable-product-list .product-list .product .product-image {
    flex: 4 3 auto;
    height: 60px;
    width: 100%
}

.filterable-product-list .product-list .product .shop-logo {
    flex: 1 4 auto;
    align-self: center;
    height: 20px;
    width: 100%
}

.filterable-product-list .product-list .product .product-image, .filterable-product-list .product-list .product .shop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    background-color: #fff
}

.filterable-product-list .product-list .product .product-image img
{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto
}

.filterable-product-list .product-list .product .product-details {
    flex: 4 1 auto;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px
}

.filterable-product-list .product-list .product .product-details .product-price {
    font-weight: 900
}

.filterable-product-list .action-bar {
    justify-content: center;
    align-items: center
}

.search-text-col {
    padding-bottom: 15px
}

.social-col {
    padding-top: 15px
}

.cross-img-24 {
    height: 24px;
    width: 24px
}

.cross-img-50 {
    height: 50px;
    width: 50px
}

.search-img-20 {
    height: 20px;
    width: 20px
}

.fa-w-16 {
    width: 16px
}

.fa-w-18 {
    width: 18px
}

.product-panel .shop-logo {
    height: 75px
}

.product-panel .shop-logo
{
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.filterable-product-list .supermarket-items .shop-logo,
.filterable-product-list .my-shopping-list .supermarket-items .shop-logo {
    height: 100px !important;
    width: 100%;
}

#header-row a{
    text-decoration: none !important;
}

a.alt-product.row,
a.product-link{
    color: unset;
    text-decoration: none;
}

.new-search{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 25px;
    font-weight: 900;
}

.busy-notice{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: #000000c9;
    color: #388e3c;
    font-weight: 900;
    width: 50%;
    max-width: 200px;
    z-index: 999;
    border-radius: 5px;
}

@media (min-width: 1200px) {
    .search-text-col {
        padding-bottom: 0
    }

    .social-col {
        padding-top: 0
    }
}

.action-bar .col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-image-container svg,
.shop-logo svg{
    width: 100%;
    height: 100%;
}

.shop-logo img{
    width: 100%;
    height: 100%;
}

.fixed-product-list-col .shop-logo{
    padding: 15px !important;
}

input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
