.sld-heading{
  width: 90%;
}

.sld-heading .storeNameLogo{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sld-heading .MuiListItemIcon-root{
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sld-heading .storeNameLogo img{
  max-width: 100%;
  max-height: 100px;
  height: auto;
  padding: 20px;
}

.sld-heading header{
  background-color: white;
  color: black;
}

.sld-heading .productImage img{
  max-width: 100%;
  max-height: 100px;
  height: auto;
}
