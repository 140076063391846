.supermarketLogo{
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.supermarketLogo img{
  max-width: 100%;
  max-height: 45px;
}

.miniStoreListItem{
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #0000000a;
}

.pt-10{
  padding-top: 10px;
}
